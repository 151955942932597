import React from "react";


export default function Footer({ props }) {
  return (
    <footer className="relative bg- pt-8 pb-6"
    style={{backgroundColor:"#14618f"}}
    >
      
      <div className="container mx-auto px-4 w-100">
        <div className="lg:w-100 flex flex-wrap">
          <div className="lg:w-4/12 px-4 w-10/12">
            <h4 className="text-3xl font-bold text-black">{props.headlineOne}</h4>
            <p className="mt-3 text-white">{props.subheadlineOne}</p>
            <h5 className="text-lg mt-0 mb-2 text-gray-700 w-25">
              <p>
                <a href={props.whatsapp} target="_blank rel=" noreferrer>
                   <button
                      className="bg-yellow text-blue-400  font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3 mt-4"
                      type="button"
                    >
                      <i
                        className="flex fab fa-whatsapp fa-2x text-white"
                      ></i>
                    </button>
                </a>
              </p>
            </h5>
          </div>
        <div className="md:w-12/12 lg:w-7/12   lg:flex flex-wrap  w-12/12   text-black" >
            <div className="w-100">  
             <ul className=" color-white px-6  align-center w-12/12 mx-auto mt-4 text-balck"> 
               {/* <span className="font-bold  mx-auto w-12/12"> {props.policies.heading}</span>  */}
              <div>  
                <div> 
              {/* <li className="mt-4 font-semibold text-white"> */}
                {/* <a href="./Returnpolicy" target="_blank" className="text-balck hover:text-blue-400"> */}
                 {/* {props.policies.policies1} */}
                {/* </a> */}
              {/* </li> */}
              {/* <li className="font-semibold text-white"> */}
                {/* <a href="./Terms" target="_blank"> */}
                {/* {props.policies.policies2} */}
                {/* </a> */}
              {/* </li> */}
              </div>
              {/* <div> */}
              {/* <li className=" font-semibold text-white "> */}
                {/* <a href="./Security " target="_blank"> */}
                {/* {props.policies.policies3} */}
                {/* </a> */}
              {/* </li> */}
              {/* <li className="font-semibold text-white "> */}
                {/* <a href="./Privacy" target="_blank"> */}
                {/* {props.policies.policies4} */}
                {/* </a> */}
              {/* </li> */}
             {/* </div> */}
               </div>  
              </ul>  
            </div>  
           <div className=" mt-4 md:w-48   w-100"> 
             <span className="font-bold ml-8 text-black"> Mail Us:</span> 
             <p className="mt-4 ml-8 font-semibold text-white"> 
             {/* {props.add1.line1} <br /> {props.add1.line2} <br /> {props.add1.line3} <br />  */}
             {/* {props.add1.line4} */} <br/> <a href={props.add1.link}>{props.add1.mail}</a>  
             </p> 
           </div> 
           <div className="  md:w-48  mt-4 md:w-12/12 "> 
             <span className="font-bold ml-8 text-"> Office Address:</span> 
             <p className="mt-4 ml-8 font-semibold text-white"> 
             {props.add2.line1} <br /> {props.add2.line2} <br /> {props.add2.line3} <br />  
             {props.add2.line4} <br/>  
               {props.add2.telephone} <a href={props.add2.link} >{props.add2.number}</a> 
            </p> 
          </div> 
          <div className="  mt-4   flex flex-col">
            <span className="font-bold ml-8 text-"> Make Money With Us:</span>
            <a href="./Affiliate "className="mt-4 ml-8 font-semibold text-white" > Become an affiliate</a>
          </div>

        </div>
      </div>
      </div>
      <hr className="my-6 border-green-500" />
      <div className="flex flex-wrap items-center md:justify-between justify-center">
        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
          <div className="text-sm text-white font-bold py-1">
           {props.copy.copyright} {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  );
}
