import React from 'react'
import { Whatsapp } from './Whatsapp'
export const Affiliate = () => {
    return (
        <div className="mt-24">
            



        <section class="bg-gray-100">
            <div class=" container flex flex-col-reverse lg:flex-row items-center gap-12 mt-14 lg:mt-24 lg:mb-4 px-2 ">
                {/* <!-- Content --> */}
                <div class="flex flex-1 flex-col items-center lg:items-start">
                <h2 class="text-bookmark-blue text-3xl font-extrabold md:text-4 lg:text-5xl text-center lg:text-left mb-6">
                    Want to Become an Affiliate
                </h2>
                <p class="text-bookmark-grey text-lg font-bold text-center lg:text-left mb-6">
                    Click on below button to join with us
                </p>
                <div class="flex justify-center flex-wrap gap-6">
                    <a  href="./Whatsapp" >
                    <button class= "rounded bg--200 text-white hover:bg-yellow-30 hover:text-yellow-800 font-bold text-3xl  bg-gray-800 ">Become an affiliate</button>
                    
                    </a>
                
                </div>
                </div>
                {/* <!-- Image --> */}
                <div class="flex l  flex-1 mb-10 md:mb-16 lg:mb-0 ">
                <img class=" sm:w-3/4 sm:h-3/4 md:w-full md:h-full rounded-xl" src="./assets/public/money.jpg" alt="" />


                </div>
            </div>

        </section>

        <main>
        
       
       
       
        
         
        <div className=' flex lg:flex-col flex-wrap   md:flex-row  items-center justify-center bg-gray-300  mt-2 mb-1  p-2' >
                    <img src="./assets/public/3.jpg" width= "170px " height="170px" className=" border-b-2   p-2 "/>
                    <img src="./assets/public/3.jpg"  width="170px" height="170px"className="border-b-2  p-2 "/>
                    <img src="./assets/public/3.jpg" width="170px" height="170px"className=" border-b-2  p-2"/>
                    <img src="./assets/public/3.jpg" width="170px"height="170px"className="border-b-2 ml-1  p-2"/>
                
        </div>
        </main>
        <footer class="text-gray-100 bg-gray-800 mt-2">
            <div class="max-w-3xl mx-auto py-6">
                <h1 class="text-center text-lg lg:text-2xl">
                Ceemutli Capacitors a unit which had a run of more than 21 years in manufacturing of full range of metalised film capacitors, both for electronics as well as electrical application, is now Ceemulti Capacitors . In new avatar , Ceemutli Capacitor will be catering to the electrical applications only.        </h1>

                <div class="flex justify-center mt-6">
                    <div class="bg-white rounded-md">
                        <div class="flex flex-wrap justify-between md:flex-row">
                        
                    </div>
                </div>

                

            

                    <div class="flex mt-4 md:m-0 md:gap-2 ">
                    <div class="ml-2">
                        <img src="./assets/public/2.jpg"  width="100px" height="100px"class=" rounded-full "/>
                    </div>
                    </div>
                </div>
            </div>
            </footer>
                </div>
    )
  
}
