import { React,useState} from "react";
//import { Link as ScrollLink} from 'react-scroll';
import { Link, Link as RouteLink } from "react-router-dom";
import * as constant from "../constant";

export default function Navbar({ props,scrollPosition}) {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
  
      <nav
        className={
          "mobile-nav flex flex-wrap items-center justify-between  px-2 py-3 bg-white  bg-opacity-50 mt-0 fixed top-0 colorbg z-50"
        }
        id="navid"
        style={{ borderBottom: "1px solid blue" }}
      >
        <div className="container mx-auto px-4  flex flex-wrap items-center justify-between">
          <div className="w-full relative flex  lg:w-auto lg:static justify-between ">
            <div className="mr-2 lg:mr-8">
            <Link
              className={
                " text-lg font-bold text-black leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              }
              to={constant.routes.home}
            >
            <img src={props.compName} alt="Logo"
            height="50px"
            width="50px"
            />
            </Link>
            </div>
            
             
            {navbarOpen === false ? (
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className={" fas fa-bars"}></i>
              </button>
            ) : (
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className={" fa fa-times"}></i>
              </button>
            )}
         </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded  bg-transparent " : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul
              className="flex flex-col justify-around lg:flex-row list-none lg:ml-auto"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <li className="flex ">
                <RouteLink
                  activeClass="active"
                  to={constant.routes.home}
                  className=" text-xs font-bold cursor-pointer uppercase px-4 py-2   hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className=""></i> {props.home}
                </RouteLink>
              </li>

              

              <li className="flex">
                <RouteLink
                  activeClass="active"
                  to={constant.routes.wiki}
                  className=" text-xs font-bold uppercase cursor-pointer px-4 py-2 rounded  hover:shadow-md hover:bg-blue-600 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="hover:bg-blue-600"></i>
                  {props.products}
                </RouteLink>
              </li>

              <li className="flex">
                <RouteLink
                  activeClass="active"
                  to={constant.routes.aboutceemulti}
                  className=" text-xs font-bold uppercase cursor-pointer px-4 py-2 rounded  hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="border-none"></i> {props.aboutus}
                </RouteLink>
              </li>
              <li className="flex ">
                <RouteLink
                  activeClass="active"
                  to={constant.routes.faq}
                  className=" text-xs font-bold uppercase cursor-pointer px-4 py-2 rounded  hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="border-none"></i> {props.faq}
                </RouteLink>
              </li>
              <li className="flex ">
                <RouteLink
                  activeClass="active"
                  className=" text-xs font-bold uppercase cursor-pointer px-4 py-2 rounded  hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                >
                  <i className="hover:bg-blue-600"></i>{" "}
                  <a href={props.link}>{props.number}</a>
                </RouteLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div></div>
      <style jsx>
        {`
          #navid .colorbg {
            background-color: #00cc44;
          }
          .mobile-nav {
            background: #eeeeeec9;
            width:100%;
          }
        `}
      </style>
    </>
  );
}
