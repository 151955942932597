import React from "react";
const wiki = ({ props }) => {
  return (
    <>
      <div className="max-w-7xl mx-auto mt-24 px-4 sm:px-6 lg:px-8 py-8 container">


        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">


            <div className="relative mt-4">
              <dt>
                <h6
                  className="flex justify-center items-center text-lg
                    px-2 rounded-full bg-blue-500 text-white  "
                >
                  {props.prod}
                </h6>
                <br />
                <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">
                  {props.subprod}
                </h5>
              </dt>
              <dd className="mt-1 ml-24 text-base text-gray-500">
                {props.data}
              </dd>
            </div>
            <div className="relative ">
              <dt>
                <br />
                <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">
                  {props.subprod1}
                </h5>
              </dt>
              <dd className="mt-1 ml-24 text-base text-gray-500">
                {props.data1}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <section className="text-indigo-100 body-font p-5 bg-gray-900">
        {props.detail.map((data, index) => (
          <>
            {index % 2 === 0 ? (
              <div className=" mx-auto flex  md:flex-row flex-col  jobcard w-full">
                <div className="lg:max-w-lg items-left lg:w-full md:w-1/2  sm:block  mr-6  w-full md:w-50">
                  <img
                    style={{ width: "600px" }}
                    className="object-cover object-center rounded "
                    alt="hero"
                    src={data.pic}
                  />
                </div>
                <div className="lg:flex-initial ml-0 lg:ml-3 md:w-1/2 lg:pr-24 md:pr-16  w-full md:w-50  flex-initial md:items-start md:text-left mb-16 md:mb-0 ">
                  <figure className="visible">
                    <div className="">
                      <div className="pt-10 lg:pl-4 pl-0">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                          {data.heading}
                        </h1>
                        <p className="text-white text-base pb-6">{data.para}</p>
                        <h6 className="mt-3 inline-block py-1 px-2 rounded-full bg-blue-500 text-white font-bold tracking-widest mb-2 ">
                          {data.subhead}
                        </h6>
                        <ul>
                          {data.tag.map((tags) => (
                            <li className="text-white text-base pb-6">
                              {tags.tag1}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
            ) : (
              <div className="mx-auto flex  md:flex-row flex-col  jobcard w-full mb-4">
                <div className=" px-4 lg:flex-initial md:w-1/2 w-full md:w-50 lg:pr-24 md:pr-16 flex-initial md:items-start md:text-left mb-16 md:mb-0 ">
                  <figure className="visible">
                    <div className="">
                      <div className="pt-10  sm:px-6">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                          {data.heading}
                        </h1>
                        <p className="text-white text-base pb-6">
                          {" "}
                          {data.para}
                        </p>
                        <h6 className="mt-3 inline-block py-1 px-2 rounded-full bg-blue-500 text-white font-bold tracking-widest mb-2 ">
                          {data.subhead}
                        </h6>
                        <ul>
                          {data.tag.map((tags) => (
                            <li className="text-white text-base pb-6">
                              {tags.tag1}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </figure>
                </div>

                <div className="lg:max-w-lg items-left lg:w-full md:w-1/2 sm:block w-full md:w-50">
                  <img
                    style={{ width: "600px" }}
                    className="object-cover object-center rounded "
                    alt="hero"
                    src={data.pic}
                  />
                </div>
              </div>
            )}
          </>
        ))}
      </section>
      <div className=" grid grid-cols-2 text-center  sticky top-16" style={{
        overflow: "scroll",

      }}> <h1 className="m-4 text-2xl font-bold">Specification Of Capacitor</h1>
        <table class="table-auto m-4 border border-green-600 ">
          <thead class="border border-green-600">
            <tr class="border border-green-600">
              {props.productDeatils.headvalue.map((res) => (
                <th class="px-4 font-bold border border-green-600 ">
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.productDeatils.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td class="px-4 border border-green-600 ">
                    {res.value}
                  </td>
                  <td className="px-4 border border-green-600">{res.voltage}</td>
                  <td className="px-4 border border-green-600">{res.canSize}</td>
                  <td className="px-4 border border-green-600">{res.packing}</td>
                  <td className="px-4 border border-green-600"><strong>{res.basicprice}</strong></td>
                </tr>
              </tbody>
            </>
          ))}
        </table>
        {/* next */}
        <h2 class=" col-span-full mx-12 text-green-900-center text-3xl font-bold align-middle justify-center ">Motor Starting Capacitor</h2>
        <table class="table-auto m-4  border border-bg-black-900">
          <thead class="border border-black-900  ">
            {/* <tr class="border border-black-900 col-spam-full text-center "></tr> */}
            <tr class="border border-black-900">

              {props.motorstarting.headvalue.map((res) => (
                <th className="px-4 border border-black-900" >
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.motorstarting.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td className="px-4 border border-green-600">
                    {res.value}
                  </td>

                  <td className="px-4 border border-green-60">{res.canSize}</td>
                  <td className="px-4 border border-green-60">{res.packing}</td>
                  <td className="px-4 border border-green-60">{res.pricepp}</td>

                </tr>
              </tbody>
            </>
          ))}
        </table>
        {/* submursible panel */}
        <h2 className="text-3xl font-bold    ">Submersible Panel Application</h2>
        <table class="table-auto m-4 border-Collapse border border-bg-black-900">


          <thead class="border border-green-600 text-center mx-4 space-y-2 ">
            <tr class="border border-green-600">

              {props.submursiblepanel.headvalue.map((res) => (
                <th className="px-4" >
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.submursiblepanel.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td className="px-4 border border-green-600">
                    {res.value}
                  </td>

                  <td className="px-4 border border-green-60">{res.canSize}</td>
                  <td className="px-4 border border-green-60">{res.packing}</td>
                  <td className="px-4 border border-green-60">{res.basicprice}</td>
                </tr>
              </tbody>
            </>
          ))}
        </table>
        {/* squaretypefor fan */}
        <h2 class="text-green-400-center text-3xl font-bold align-middle justify-center ">Square Fan Capacitor</h2>
        <table class="table-auto m-4 border-Collapse border border-bg-black-900">
          <thead class="border border-green-600 text-center mx-4 space-y-2 ">
            {/* <tr class="border border-green-600 bg-blue-600 row-span-full  align-middle table-row"></tr> */}
            <tr class="border border-green-600">
              {props.quaretypeforfan.headvalue.map((res) => (
                <th className="px-4" >
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.quaretypeforfan.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td className="px-4 border border-green-600">
                    {res.value}
                  </td>

                  <td className="px-4 border border-green-60">{res.canSize}</td>
                  <td className="px-4 border border-green-60">{res.packing}</td>
                  <td className="px-4 border border-green-60">{res.pricepp}</td>

                </tr>
              </tbody>
            </>
          ))}
        </table>
        {/* motorrun */}
        <h2 className="text-green-400 text-3xl font-bold text-center ">Motor Run Terminal</h2>
        <table class="table-auto m-4 border-Collapse border border-bg-black-900">
          <thead class=" text-center mx-4 ">
            {/* <tr class=" bg-blue-600 table-row col-span-2"></tr> */}
            <tr class="border border-green-600">
              {props.motorrun.headvalue.map((res) => (
                <th className="px-4" >
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.motorrun.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td className="px-4 border border-green-600">
                    {res.value}
                  </td>
                  <td className="px-4 border border-green-60">{res.voltage}</td>
                  <td className="px-4 border border-green-60">{res.canSize}</td>
                  <td className="px-4 border border-green-60">{res.packing}</td>
                  <td className="px-4 border border-green-60">{res.pricepp}</td>

                </tr>
              </tbody>
            </>
          ))}
        </table>

        {/*oil filled  */}
        <h2 class="text-green-400-center text-3xl font-bold align-middle justify-center ">Oil Fill For Fan Apllication</h2>
        <table class="table-auto m-4 border-Collapse border border-bg-black-900">
          <thead class="border border-green-600 text-center mx-4 space-y-2 ">
            {/* <tr class="border border-green-600 bg-blue-600 row-span-full  align-middle table-row"></tr> */}
            <tr class="border border-green-600">

              {props.oilfill.headvalue.map((res) => (
                <th className="px-4" >
                  <h2 >{res.head1} </h2>
                </th>
              ))}
            </tr>
          </thead>
          {props.oilfill.tableValue.map((res) => (
            <>
              <tbody class="border border-green-600">
                <tr class="border border-green-600">
                  <td className="px-4 border border-green-600">
                    {res.value}
                  </td>
                  <td className="px-4 border border-green-60">{res.voltage}</td>
                  <td className="px-4 border border-green-60">{res.canSize}</td>
                  <td className="px-4 border border-green-60">{res.packing}</td>
                  <td className="px-4 border border-green-60">{res.pricepp}</td>

                </tr>
              </tbody>
            </>
          ))}
        </table>
      </div>
      <h2 className="m-3">
        <strong className="m-4 align-middle pl-5">Technical Specification</strong>
      </h2>
      <table class="table-auto m-4">
        {props.table.map((res) => (
          <>
            <thead>
              <tr>
                <td className="px-4 font-bold ">
                  <h2>{res.tabletitle} </h2>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4">
                  <strong>{res.col1}</strong>
                </td>
                <td className="px-4">{res.col2}</td>

           


              </tr>
            </tbody>
          </>
        ))}
      </table>
      <h1 className="m-4 font-serif px-3"> The values of the capacitor are indicative only and subject to <br /> change. Other capacitor can also be supplies on specific demand. <br /> For commonly used values,please ref to the rate list</h1>

      <div className="container px-3  mt-4  mb-4">
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <h4 className="text-3xl font-semibold m-0">{props.head}</h4>
            <p>{props.para}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default wiki;
