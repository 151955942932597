import {React,useState} from 'react'

export const Whatsapp = () => {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [product, setProduct] = useState();  
  return (

    
        <div className='mt-24'>
             <form class="w-full max-w-lg">
  <div class="flex flex-wrap -mx-3 ">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name" name="name">
        Name
      </label>
      <input id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  placeholder="Jane"></input>
      
    </div>
   
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password " name="number">
        Number
      </label>
      <input  id="number"
                name="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                type="number" 
      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  placeholder="number"></input>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-2">
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city" >
        Email
      </label>
      <input  id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Enter Your email"></input>
    </div>
  </div> 
  <div class="flex flex-wrap -mx-3 mb-2">
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city" name="product">
        Product
      </label>
      <input    id="product"
                name="product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                type="text"
      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  placeholder=""></input>
    </div>
  </div>
   
    
    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
    <a class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded" type="button" href={
                "https://wa.me/919873660345?text=" +
                "Name: " +
                name +
                "%0a" +
                "Phone: " +
                number +
                "%0a" +
                "Email: " +
                email +
                "%0a" +
                "Product :" +
                product
              }>
                Submit
      
    </a>
  </div>
</form>   

      </div>
    )
}
