import React from "react";

export const Privacy = ({props}) => {
    console.info(props,"policy")
    
  return (
    <>
      <div className="container mx-auto px-6 mt-24 mb-6">
        
          {
          props.privacy.map(member=>{
              return(
        <div>
              <strong className="text-lg">{member.header}</strong>
              <p className="mt-4 ">{member.data}</p>
              <h2 className="mt-4 font-semibold">{member.p1}</h2>
              <p className="mt-4">{member.data2}</p>
          </div>
         ) } )} 
      </div>
    </>
  );


};