import React from "react";

export const Security = ({props}) => {
  return (
    <div className="container mx-auto px-6 mt-24 mb-6">
      {
        props.security.map(members=>{
          return(
    <div>
          <strong className="text-lg">{members.header}</strong>
          <h2 className="mt-4 font-semibold">{members.subhead1}</h2>
           <p className="mt-4 ">{members.data1}</p> 
           <h2 className="mt-4 font-semibold">{members.subhead2}</h2>
              <p className="mt-4">{members.data2}</p> 
              <h2 className="mt-4 font-semibold">{members.subhead3}</h2>
              <p className="mt-4">{members.data3}</p> 
              <h2 className="mt-4 font-semibold">{members.subhead4}</h2>
              <p className="mt-4">{members.data4}</p> 
      </div>
     ) } )
      }
    </div>
  );
};
