import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProductView } from "./ProductView";

function ProductsDetails({ props }) {
  const { id } = useParams();
  const currentURL = window.location.href.split("/")[0];
  const [number, setNumber] = useState(() => {
    const saved = localStorage.getItem("number");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [name, setName] = useState(() => {
    
    const saved = localStorage.getItem("name");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  useEffect(() => {
    localStorage.setItem("number", JSON.stringify(number));
  }, [number]);
  useEffect(() => {
    localStorage.setItem("name", JSON.stringify(name));
  }, [name]);
  const clearItem = () => {
    localStorage.clear();
    setNumber("");
    setName("");
  };

  const formData = new FormData();
  formData.append("Quantity", 90);
  formData.append("TXN_AMOUNT", 90);
  const [quantity, setQuantity] = useState();

  return (
    <>
    
      {props
        .filter((val) => {
          if (id === val.id) return val;
        })
        .map((val, key) => (
          <figure className="flex items-center justify-around h-44 w-44 mt-16 border-black mb-10">
            <div className="block mb-10 md:flex">
              <div className="mt-0 ml-0 ">
                <ProductView images={val.details.images} />
              </div>
              <div className=" text-gray-500 sm:text-2xl md:itemes-center md:mt-24 md:pt-16 lg:text-base ">
                <div className="mt-2 md:mt-24">
                  <div className="user" key={key}>
                    <h1 className="text-lg font-medium text-black mt-5 sm:mb-1 sm:text-gray-500 sm:mt-20">
                      {val.name}
                    </h1>
                    <h2 className="text-lg font-medium text-black sm:mb-1 sm:text-blue-500">
                      {val.description}
                    </h2>
                    <h3 className="text-lg font-large text-black sm:mb-1 mr-8 sm:text-gray-500 inline-block">
                      {val.price}
                    </h3>
                    <h4 className="text-lg font-medium text-black sm:mb-1  sm:text-gray-500 inline-block">
                      {val.currency}
                    </h4>
                  </div>
                </div>
                <div className=" h-10 w-32 ">
                  <div className="flex">
                    <div className="flex flex-row h-10 w-full rounded-lg  bg-transparent mt-1 "></div>
                  </div>
                </div>
                <div>
                  <form>
                    <input
                      type="text"
                      name="TXN_AMOUNT"
                      value={val.price}
                      hidden
                    />
                    <select
                    id="name"
                    name="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    type="number"
                    className="form-select text-black text-lg  font-bold p-4 rounded w-full"
                    required="required"
                    style={{ width: "200px" }}
                  >
                    <option>Select the quantity</option>
                    {val.quantity.map((item) => (
                      <option required="required">{item}</option>
                    ))}
                  </select>
                    <input
                      className="flex mt-4 border-b-2"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required="required"
                      placeholder="First Name"
                      name="Name"
                    />
                    <input
                      className="flex mt-4 mb-4 border-b-4 "
                      type="tel"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      required="required"
                      placeholder="Enter Phone Number"
                      name="MOBILE"
                    />

<a
                    href={
                      "https://wa.me/919873660345?text=" +
                      "Name: " +
                      val.name +
                      "%0a" +
                      "Quantity:" +
                       quantity + 
                      "%0a" +
                      "Price:" +
                      val.price *quantity
                      +
                      "Name:" +
                      name
                     
                    }
                    className=" ml-8  px-4 py-2  text-white font-semibold outline-none  bg-blue-500"
                  >
                    Buy Now
                  </a>
                  </form>
                  <button
                    className=" text-xs font-bold cursor-pointer  bg-blue-500 uppercase px-4 py-2 mt-4 text-white  items-center hover:shadow-md    lg:mr-1 lg:mb-0 ml-3 mb-3"
                    onClick={clearItem}
                  >
                    Reset User Info
                  </button>
                </div>
              </div>
            </div>
          </figure>
        ))}
    </>
  );
}
export default ProductsDetails;
