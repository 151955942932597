import React from "react";
const Returnpolicy = ({props}) => {
  console.log(props,"policy")
  return (
    <div className="container mx-auto px-6 mt-24 mb-6 h-0"
    style={{
      height:'350px'
    }}
    >
     
        {
          props.back.map(members=>{
              return(
        <div>
              <strong className="text-lg">{members.header}</strong>
               <p className="mt-4 "
                // style={{
                //   marginBottom:'153px'
                // }}
               >{members.data}</p>  
          </div>
         ) } )}
    </div>
  );
};
export default Returnpolicy;
