import React from "react";

const Terms = ({props}) => {
  return (
    <div className="container mx-auto px-6 mt-24 mb-20 h-1/2"
    style={{
      height:'250px'
    }}
    >
      {
         props.terms.map(members=>{
          return(
    <div>
          <strong className="text-lg">{members.header}</strong>
           <p className="mt-4 mb-20 lg:mb-30"
            
           >{members.data}</p>  
      </div>
     ) } )
      }
    </div>
  );
};
export default Terms;
