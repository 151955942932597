import React from "react";
function Faq({ props }) {
  return (
    <div>
      <div className=" mt-24">
        <section class="text-gray-700">
          <div class="container px-5 py-14 mx-auto">
            <div class="text-center mb-5">
              <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
               {props.fre1}
              </h1>
              <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
               {props.des}
              </p>
            </div>

            {props.fre.map((val) => (
              <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                <div class="w-full px-4 py-2">
                  <details class="mb-4">
                    <summary class="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                      {val.question}
                    </summary>

                    <span>{val.answer}</span>
                  </details>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
export default Faq;
