import { useState } from "react";

export const ProductView = ({ images }) => {
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  return (
    <>
      <div className="md:flex md:flex-row  border-8 border-black ">
        <div className="mt-8 w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
          <div className="w-full sm:w-3/4 mb-1">
            <img
              className="mediaquery"
              alt="description"
              src={images[currentOptionIndex]}
              style={{
                height: "400px",
                width: "300px",
              }}
            ></img>
          </div>
          <div className="flex flex-wrap">
            {images.map((option, i) => (
              <h3
                className={`option ${i === currentOptionIndex && "selected"}`}
                key={option}
                src={option}
                onClick={() => setCurrentOptionIndex(i)}
              >
                <img
                  className="h-18 w-16 p-2"
                  src={option}
                  alt="description"
                  style={{
                    height: "6rem",
                    width: "4rem",
                  }}
                />
              </h3>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
