module.exports = Object.freeze({
    ACTION_INVALID: 'This action is invalid',
    ACTION_VALID: 'Some other action',
    routes:{
        "home":"/home",
        "aboutceemulti":"/home/aboutceemulti",
        "organicProducts":"/op",
        "wiki":"/wiki",
        "productsByCategory":"/products/:categoryId",
        "productDetails":"/productDetails/:id",
        "Returnpolicy":"/Returnpolicy",
        "Terms":"/Terms",
        "Security":"/Security",
        "Privacy":"/Privacy",
        "faq":"/faq",
        "affiliate":"/affiliate",
        "Whatsapp":"/Whatsapp"
    },
    scroll:{
        "home":"home",
        "aboutceemulti":"aboutceemulti",
    }
  });