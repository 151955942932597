import React from "react";

import Home from "components/Home.js";
//import Wiki from "components/Wiki.js";

export default function Landing({ props }) {
  // const [navbarOpen, setNavbarOpen] = React.useState(true);

  return (
    <>
      <main>
        <div
          className="flex items-center justify-center pt-10 lg:min-h-full mt-6 mx-3 h-3/4 mt-24"
          // style={{
          //   minHeight: '75vh',
          // }}
        >
          <video
            autoPlay
            loop
            muted
            className="w-screen min-w-full min-h-full max-w-none"
          >
            <source src={props.bannerVideo} type="video/mp4" />
          </video>
        </div>
        <Home props={props.home} />
        

      </main>
    </>
  );
}
