import React from "react";

const Aboutceemulti = ({ props }) => {
  return (
    <div className="container mx-4 py-4 m-4 mt-5"style={{marginTop:"48px"}}>
      <div className="m-4 mt-8">
        <h2>
          <strong> {props.title}</strong>
        </h2>
        <p className="mt-3 mx-5">{props.about}</p>
        <p className="mt-3">
          <strong>{props.about2}</strong>
        </p>
        <ul className="p-4">
          {props.line.map((point) => (
            <li className="list-disc">{point.p}</li>
          ))}
        </ul>
      </div>
      <div></div>
      <h2 className="m-3">
        <strong className="mt-4">Factsheet</strong>
      </h2>
      <table class="table-auto m-4">
        {props.table.map((res) => (
          <>
            <thead>
              <tr>
                <td className="px-4 font-bold ">
                  <h2>{res.tabletitle} </h2>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4">
                  <strong>{res.col1}</strong>
                </td>
                <td className="px-4">{res.col2}</td>
              </tr>
            </tbody>
          </>
        ))}
      </table>
    </div>
  );
};

export default Aboutceemulti;
