import React from "react";

import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import ProductDetails from "./components/ProductDetails";
import * as constant from "./constant";
import database from "./data/database";
import Navbar from "components/Navbar";
import Wiki from "components/Wiki";
import Aboutceemulti from "components/Aboutceemulti";
import Footer from "components/Footer";
import Returnpolicy from "components/Returnpolicy";
import Terms from "components/Terms";
import { Security } from "components/Security";
import { Privacy } from "components/Privacy";
import Faq from "components/Faq";
import { Affiliate } from "components/Affiliate";
import { Whatsapp } from "components/Whatsapp";
//import Category from "components/Category";
class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      database: database,
    };
  }
  render() {
    return (
      <>
        <BrowserRouter>
          <Navbar transparent props={database.navbar} />
          {/* <Category transparent props={database.categories}/> */}
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Landing props={database} />}
            />
            {/* <Route
              path={constant.routes.productsByCategory}
              render={({ match }) => (
                <Products
                  props={database.products.filter(
                    (p) => p.categoryId === match.params.categoryId
                  )}
                />
              )}
            /> */}

            <Route
              exact
              path={constant.routes.Returnpolicy}
              component={() => <Returnpolicy props={database.policy} />}
            />
            <Route
              exact
              path={constant.routes.Terms}
              component={() => <Terms props={database.policy} />}
            />
            <Route
              exact
              path={constant.routes.Security}
              component={() => <Security props={database.policy} />}
            />
            <Route
              exact
              path={constant.routes.Privacy}
              component={() => <Privacy props={database.policy} />}
            />
           
            <Route
              exact
              path={constant.routes.home}
              component={() => <Landing props={database} />}
            />
              <Route
              exact
              path={constant.routes.affiliate}
              component={() => <Affiliate />}
            />
              <Route
              exact
              path={constant.routes.Whatsapp}
              component={() => <Whatsapp />}
            />
            <Route
              exact
              path={constant.routes.wiki}
              component={() => <Wiki props={database.wiki} />}
            />
            <Route
              exact
              path={constant.routes.aboutceemulti}
              component={() => <Aboutceemulti props={database.aboutceemulti} />}
            />
            <Route
              exact
              path={constant.routes.faq}
              component={() => <Faq props={database.faq} />}
            />
            <Route
              path={constant.routes.productDetails}
              component={() => <ProductDetails props={database.products} />}
            />
            <Redirect exact path="PaytmKit/pgRedirect.php" />
          </Switch>
        </BrowserRouter>
        <Footer props={database.footer} />
      </>
    );
  }
}
ReactDOM.render(<Main />, document.getElementById("root"));
