import React from "react";
const Home = ({ props }) => {
  return (
    <>
      <div className="max-w-7xl mx-auto mt-24 px-4 sm:px-6 lg:px-8 py-8 container">
        <div className="lg:text-center">
          <p className="mt-4 max-w-2xl text-xl text-blue-500 lg:mx-auto">
            {props.content}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <h6
                  className="flex justify-center items-center text-lg
                  px-2 rounded-full bg-blue-500 text-white  "
                >
                  {props.subtopic}
                </h6>
                <br />
                <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">
                  {props.topic}
                </h5>
              </dt>
              <ul>
                {props.points.map((data) => (
                  <li className="mt-1 ml-24 text-base text-gray-500">
                    {data.p}
                  </li>
                ))}
              </ul>
            </div>
            <div className="relative mt-4">
              <dt>
                <h6
                  className="flex justify-center items-center text-lg
                    px-2 rounded-full bg-blue-500 text-white  "
                >
                  {props.why}{" "}
                </h6>
                <br />
                <h5 className="pt-1 ml-24 text-lg leading-6 font-medium text-gray-900">
                  {props.subtopic1}
                </h5>
              </dt>
              {props.top.map((data) => (
                <>
                  <dd className="mt-1 ml-24 text-base text-blue-500">
                    {data.topic}
                  </dd>
                  <dd className="mt-1 ml-24 text-base text-gray-500">
                    {data.detail}
                  </dd>
                </>
              ))}
            </div>


          </dl>
        </div>
      </div>



    </>
  );
};
export default Home;
